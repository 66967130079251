<template>
    <vue-table-card :showSearchBox="false" class="m-3 c-table" title="Breeds" :fields="fields" :url="listUrl"
                    :per-page="5"
                    ref="table">
        <template #buttons>
            <btn icon="fa fa-plus" size="sm" @click="showAddModal" text="Add"/>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <!--                <delete-btn color="primary" text="Edit" icon="" size="xs" @click="setEdit(rowData)"/>-->
                <delete-btn size="xs" @click="setDelete(rowData)"/>
            </div>
        </template>

        <template #footer>
            <modal no-close-on-backdrop title="Add Breed" ref="addModal" width="30r" header-color="primary">
                <s-form @submit="submitClicked('add')" ref="form">
                    <validated-input label="Name" name="name" v-model="model.name" :rules="{required : true}"
                                     :disabled="loading"/>
                    <btn size="sm" text="Save" :loading="loading" :disabled="loading" loading-text="Saving.."/>
                </s-form>
            </modal>

            <modal no-close-on-backdrop title="Edit Breed" ref="editModal" width="30r" header-color="primary">
                <s-form @submit="submitClicked('edit')" ref="form">
                    <validated-input label="Name" name="name" v-model="model.name" :rules="{required : true}"
                                     :disabled="loading"/>
                    <btn size="sm" text="Update" :loading="loading" :disabled="loading" loading-text="Updating.."/>
                </s-form>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
                <p>You are about to delete the Breed. Are you sure?</p>
                <template #loading>
                    <loading-animation/>
                    Please wait while we delete the Breed.
                </template>
            </delete-modal>
            <modal ref="confirmModal" no-close-on-esc title="Message" class="text-center no-close" width="30r"
                   no-close-on-backdrop>
                <p class="text-left">Sorry, You cannot delete the Breed
                    as you have data in Bulls with the selected Breed.</p>
                <div class="fl-x-br">
                    <btn size="xs" @click="$refs.confirmModal.close()" class="w-20p ml-2" text="Ok"/>
                </div>
            </modal>
        </template>

    </vue-table-card>
</template>

<script>
// import urls from '@/data/urls';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'BreedsListing',
    data () {
        return {
            loading: false,
            listUrl: urls.admin.breed.list,
            deleteUrl: urls.admin.breed.delete,
            addUrl: urls.admin.breed.add,
            editUrl: urls.admin.breed.edit,
            deletingItem: {
                id: ''
            },
            fields: [
                {
                    name: 'slno',
                    title: 'No'
                },
                {
                    name: 'name',
                    sortField: 'name',
                    title: 'Name'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right',
                    title: 'Actions'
                }
            ],
            model: {
                name: ''
            }
        };
    },
    methods: {
        showAddModal () {
            this.model = {};
            this.$refs.addModal.show();
        },
        setEdit (item) {
            this.model = { ...item };
            this.$refs.editModal.show();
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteSuccess (response) {
            const json = response.data;
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            if (json.error === false) {
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$refs.table.refreshTable();
            } else {
                this.$refs.confirmModal.show();
            }
        },
        async submitClicked (action) {
            const that = this;
            that.loading = true;
            if (action === 'add') {
                that.action = that.addUrl;
            }
            if (action === 'edit') {
                that.action = that.editUrl;
            }
            const response = await axios.form(that.action, that.model);
            const json = response.data;
            if (json.error === false) {
                if (action === 'add') {
                    that.formSuccess();
                }
                if (action === 'edit') {
                    that.formSuccess1();
                }
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess () {
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$notify('Breed Saved Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        formSuccess1 () {
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$notify('Breed Updated Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
        }
    }
};
</script>

<style scoped>

</style>
